import React, {
    PropsWithChildren,
    useContext,
    useEffect,
    useState,
} from 'react'
import { motion } from 'framer-motion'
// @ts-ignore
import { TransitionState } from 'gatsby-plugin-transition-link'
import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import { LayoutContext } from '../Layout/Layout'
import { useHeader, useMobile, useTablet } from '../../utilities/hooks'

type StyleProps = { width: number; height: number }
const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
    createStyles<string, StyleProps>({
        content: {
            padding: theme.spacing(2),
            [theme.breakpoints.down('lg')]: {
                paddingTop: ({ height }: StyleProps) =>
                    `calc(${height}px + ${theme.spacing(3)})`,
            },
            [theme.breakpoints.up('lg')]: {
                paddingLeft: ({ width }: StyleProps) =>
                    `calc(${width}px + ${theme.spacing(1)})`,
            },
        },
    })
)

const Transition: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    const { navigationRef, fontsLoaded } = useContext(LayoutContext)
    const [navSize, setNavSize] = useState({ width: 0, height: 0 })

    const isUsingHeader = useHeader()
    const isMobile = useMobile()
    const isTablet = useTablet()

    const styles = useStyles({
        width: navSize.width,
        height: navSize.height,
    })

    useEffect(() => {
        setTimeout(() => {
            const width = navigationRef.current
                ? navigationRef.current.offsetWidth
                : 0
            const height = navigationRef.current
                ? navigationRef.current.offsetHeight
                : 0
            setNavSize({ width: width, height: height })
        }, 50)
    }, [navigationRef, isUsingHeader, isMobile, isTablet, fontsLoaded])

    return (
        <TransitionState>
            {({ mount }: any) => {
                return (
                    <motion.section
                        className={styles.content}
                        initial={{ opacity: 0 }}
                        animate={{
                            opacity: mount ? 1 : 0,
                        }}
                        transition={{ duration: 0.35, ease: 'easeInOut' }}
                    >
                        {children}
                    </motion.section>
                )
            }}
        </TransitionState>
    )
}

export default Transition
